import type { FC } from 'react';
import { FormCheck } from 'react-bootstrap';
import type { UniqueIdentifier } from '@dnd-kit/core';

import type { Column, ColumnVisibilityModel } from 'src/components/DataGrid';
import { SortableMenuItem } from './SortableMenuItem';

interface DragOverlayItemProps {
  id: UniqueIdentifier;
  disableChangingVisibility: boolean;
  columnVisibility: ColumnVisibilityModel | undefined;
  allTableFields: Column[];
}

const DragOverlayItem: FC<DragOverlayItemProps> = ({
  id,
  disableChangingVisibility,
  columnVisibility = {},
  allTableFields,
}) => {
  const item = allTableFields.find(
    (column: Column) => column.field === id || column.groupName === id
  );

  if (!item) {
    return null;
  }

  const itemNaming = item.groupName ?? item.field;
  const itemLabel = item.groupName ?? item.headerName;

  const isChecked =
    columnVisibility[itemNaming] !== undefined
      ? columnVisibility[itemNaming]
      : item.visible !== false;

  const isDisabled = disableChangingVisibility || item.hideable === false;

  return (
    <SortableMenuItem
      key={id}
      id={id}
      disabled={isDisabled}
      dataTestid="column-visibility-menu-item-overlay"
    >
      <FormCheck
        defaultChecked={isChecked}
        className="ml-2 my-2"
        disabled={isDisabled}
        label={itemLabel}
        name={itemNaming}
      />
    </SortableMenuItem>
  );
};

export default DragOverlayItem;
