import { type ChangeEvent, type FC, useCallback, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import type { Active, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  type DragOverEvent,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Box from '@mui/material/Box';

import type {
  ColumnOrderModel,
  ColumnVisibilityModel,
  PinnedColumnsModel,
} from 'src/components/DataGrid';
import type { Field, NumbersOfGroupedColumnsToShowModel } from 'src/views/types';
import DragOverlayItem from '../DragOverlayItem';
import ColumnVisibilityMenuDropZone from './ColumnVisibilityMenuDropZone';
import { arrayMove, insertAtIndex, removeAtIndex } from './ColumnVisibilityMenuUtils';

import 'src/css/column-visibility-menu.css';

export interface ColumnVisibilityMenuProps {
  columnOrder: ColumnOrderModel | undefined;
  allTableFields: Field[];
  pinnedColumns: PinnedColumnsModel | undefined;
  columnVisibility: ColumnVisibilityModel | undefined;
  numbersOfGroupedColumnsToShow?: NumbersOfGroupedColumnsToShowModel | undefined;
  onColumnOrderChange: (model: ColumnOrderModel) => void;
  onColumnPinnedChange: (model: PinnedColumnsModel) => void;
  onColumnVisibilityChange: (model: ColumnVisibilityModel) => void;
  onNumbersOfGroupedColumnsToShowChange?: (model: NumbersOfGroupedColumnsToShowModel) => void;
  enableNumbersOfGroupedColumnsToShowFeature?: boolean;
  disableChangingVisibility: boolean;
  disableChangingOrder: boolean;
}

interface ArrangedColumns {
  pinnedLeft: string[];
  centerColumns: string[];
  pinnedRight: string[];
}

interface DnDKitDroppableData {
  sortable: {
    containerId: keyof ArrangedColumns;
    index: number;
    items: string[];
  };
}

interface BeforeDrag {
  visibility?: boolean;
  container?: string;
}

export const ColumnVisibilityMenu: FC<ColumnVisibilityMenuProps> = ({
  columnOrder,
  allTableFields,
  pinnedColumns,
  columnVisibility = {},
  numbersOfGroupedColumnsToShow,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onColumnPinnedChange,
  onNumbersOfGroupedColumnsToShowChange,
  enableNumbersOfGroupedColumnsToShowFeature,
  disableChangingVisibility = false,
  disableChangingOrder = false,
}) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier>(0);
  const [isMoveDisallowed, setIsMoveDisallowed] = useState(false);
  const [beforeDrag, setBeforeDrag] = useState<BeforeDrag>({});

  const primaryColumn = allTableFields.find(column => column.primary)?.field;

  const pinnedLeft = [
    ...(primaryColumn ? [primaryColumn] : []),
    ...(pinnedColumns?.left?.filter(c => c !== primaryColumn) ?? []),
  ];

  const pinnedRight = pinnedColumns?.right ?? [];

  const groupedCenterColumns = [
    ...new Set(allTableFields.filter(column => column.groupName).map(item => item.groupName!)),
  ];

  let centerColumns = allTableFields
    .filter(column => column.field !== primaryColumn && !column.groupName)
    .map(column => column.field)
    .filter(column => !(pinnedLeft.includes(column) || pinnedRight.includes(column)))
    .concat(groupedCenterColumns);

  if (columnOrder) {
    centerColumns = centerColumns.sort((a, b) => {
      const aIndex = columnOrder.includes(a) ? columnOrder.indexOf(a) : columnOrder.length;
      const bIndex = columnOrder.includes(b) ? columnOrder.indexOf(b) : columnOrder.length;
      return aIndex - bIndex;
    });
  }

  const arrangedColumns: ArrangedColumns = { pinnedLeft, centerColumns, pinnedRight };

  const changeVisibility = useCallback(
    (field: UniqueIdentifier, value: boolean) => {
      onColumnVisibilityChange({
        ...columnVisibility,
        [field]: value,
      });
    },
    [columnVisibility, onColumnVisibilityChange]
  );

  const handleVisibilityChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeVisibility(e.target.name, e.target.checked);
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getVisibility = (colId: UniqueIdentifier): boolean => columnVisibility[colId] ?? false;

  const getContainer = (colId: UniqueIdentifier) =>
    centerColumns.includes(colId as string)
      ? 'center'
      : pinnedLeft.includes(colId as string)
        ? 'pinnedLeft'
        : 'pinnedRight';

  const handleDragStart = ({ active }: { active: Active }) => {
    setActiveId(active.id);
    setBeforeDrag({ visibility: getVisibility(active.id), container: getContainer(active.id) });
  };

  const handleDragCancel = () => setActiveId(0);

  const isPinnable = (field: UniqueIdentifier) => {
    const column = allTableFields.find(column => column.field === field);
    return column && column.pinnable !== false && !column.periodise;
  };

  const handleDrag = (event: DragOverEvent | DragEndEvent, isDragEnd: boolean) => {
    const { active, over } = event;

    if (over == null) {
      return;
    }
    const currentActive = active.data.current as DnDKitDroppableData;
    const currentOver = over.data.current as DnDKitDroppableData | undefined;

    const activeContainer = currentActive.sortable.containerId;
    let overContainer = currentOver?.sortable.containerId;
    if (!overContainer) {
      if (over.id in arrangedColumns) {
        overContainer = over.id as keyof ArrangedColumns;
      } else return;
    }

    if (activeContainer !== overContainer && !isPinnable(active.id)) {
      setIsMoveDisallowed(true);
    }

    if (activeContainer !== overContainer && isPinnable(active.id)) {
      const activeIndex = currentActive.sortable.index;
      const overIndex = currentOver?.sortable.index ?? 0;

      const newArrangedColumns = moveBetweenContainers(
        arrangedColumns,
        activeContainer,
        activeIndex,
        overContainer,
        overIndex,
        active.id
      );

      onColumnOrderChange(newArrangedColumns.centerColumns);
      onColumnPinnedChange({
        left: newArrangedColumns.pinnedLeft,
        right: newArrangedColumns.pinnedRight,
      });

      changeVisibility(active.id, true);
    }

    if (isDragEnd && activeContainer === overContainer) {
      const activeIndex = currentActive.sortable.index;
      const overIndex = currentOver?.sortable.index ?? 0;

      const newArrangedColumns = {
        ...arrangedColumns,
        [overContainer]: arrayMove(arrangedColumns[overContainer], activeIndex, overIndex),
      };

      onColumnOrderChange(newArrangedColumns.centerColumns);
      onColumnPinnedChange({
        left: newArrangedColumns.pinnedLeft,
        right: newArrangedColumns.pinnedRight,
      });
    }

    if (isDragEnd) {
      const prevVisibility = beforeDrag.visibility;

      if (
        getContainer(activeId) === beforeDrag.container &&
        getVisibility(activeId) !== prevVisibility &&
        typeof prevVisibility !== 'undefined'
      ) {
        changeVisibility(activeId, prevVisibility);
      }

      setIsMoveDisallowed(false);
      setActiveId(0);
    }
  };

  const handleDragOver = (event: DragOverEvent) => handleDrag(event, false);
  const handleDragEnd = (event: DragEndEvent) => handleDrag(event, true);

  const moveBetweenContainers = (
    arrangedColumns: ArrangedColumns,
    activeContainer: keyof ArrangedColumns,
    activeIndex: number,
    overContainer: keyof ArrangedColumns,
    overIndex: number,
    activeId: UniqueIdentifier
  ) => {
    const moveBetweenContainersRet = {
      ...arrangedColumns,
      [activeContainer]: removeAtIndex(arrangedColumns[activeContainer], activeIndex),
      [overContainer]: insertAtIndex(arrangedColumns[overContainer], overIndex, activeId),
    };
    return moveBetweenContainersRet;
  };

  return (
    <div className={isMoveDisallowed ? 'not-allowed' : ''}>
      <NavDropdown title="Columns" id="columnVisibilityMenu">
        <DndContext
          collisionDetection={pointerWithin}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
        >
          <Box sx={{ maxHeight: '75vh', overflowY: 'auto', minWidth: '26rem' }}>
            {Object.keys(arrangedColumns).map((key: string) => (
              <ColumnVisibilityMenuDropZone
                id={key}
                items={arrangedColumns[key as keyof ArrangedColumns]}
                key={key}
                disableChangingOrder={disableChangingOrder}
                disableChangingVisibility={disableChangingVisibility}
                columnVisibility={columnVisibility}
                allTableFields={allTableFields}
                handleChange={handleVisibilityChange}
                numbersOfGroupedColumnsToShow={numbersOfGroupedColumnsToShow}
                onNumbersOfGroupedColumnsToShowChange={onNumbersOfGroupedColumnsToShowChange}
                enableNumbersOfGroupedColumnsToShowFeature={Boolean(
                  enableNumbersOfGroupedColumnsToShowFeature
                )}
              />
            ))}
          </Box>
          <DragOverlay>
            {activeId ? (
              <DragOverlayItem
                disableChangingVisibility={disableChangingVisibility}
                columnVisibility={columnVisibility}
                id={activeId}
                allTableFields={allTableFields}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </NavDropdown>
    </div>
  );
};
