import { type ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Col, Container, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Dropdown } from 'src/components/Dropdown';
import { ValidTextInput } from 'src/components/UserEnteries';
import { Button, SectionDropdownOption } from 'src/css/styled-components';
import { useMarket } from 'src/markets/hooks/useMarket';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import { type BrandLine, BrandLineTypes, type Sale, TextEntryEnum } from 'src/shared';
import { type RootState, useAppSelector } from 'src/store';
import { goToCreate, goToStep2 } from 'src/store/features/brandLineAdd/actions';
import { selectSelectedBrandLinesWithUniqueIds } from 'src/store/features/brandLineAdd/selectors';
import { useSyncContext } from 'src/sync';
import { pxToRem } from 'src/theme/utils';
import type { Entity } from 'src/types/Entity';
import type { CategoriesEntity } from 'src/types/entity/Categories';
import BrandLineTable from './BrandLineTable';
import { SELECT_CATEGORY_PLACEHOLDER } from './constants';
import { Heading, Label, WarningDiv } from './styledComponents';
import getDuplicatedBrandLineGUIDs from './utils/getDuplicatedBrandLineGUIDs';
import {
  convertNewToOldBrandLines,
  createSubcategoriesTree,
  getFilteredBrandLines,
} from './utils/stepOne';

interface StepOneProps {
  closeModal: () => void;
  modalHeight: number;
}

const StepOne = ({ closeModal, modalHeight }: StepOneProps) => {
  const {
    market: { marketId: country },
  } = useMarket();

  const dispatch = useDispatch();

  const selected = useAppSelector(selectSelectedBrandLinesWithUniqueIds);

  const { getEntities } = useSyncContext();

  const sales = getEntities('brandSales') as Sale[];

  const allBrandSales = sales.filter(item => item.countryId === country);

  const allBrandLines: Entity[] = useRecords('brandLines');

  const brandLines: Entity[] = allBrandLines.filter(
    (item: Partial<DenormalizedBrandLine>) => item.brandLineTypeId !== BrandLineTypes.FORECAST
  );
  const allBrands = useRecords('brands');
  const allOrigins = useRecords('origins');
  const allOwners = useRecords('owners');
  const allCategories = useRecords<CategoriesEntity>('categories');

  const preselectedCategory = useAppSelector(
    (state: RootState) => state.brandLineAdd.step1?.preselectedCategory
  );

  const { options, categoriesLevel5 } = useMemo(
    () => createSubcategoriesTree(allCategories),
    [allCategories]
  );

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [brandLinesForSelectedCategory, setBrandLinesForSelectedCategory] = useState<BrandLine[]>(
    []
  );

  useEffect(() => {
    if (preselectedCategory) {
      setSelectedCategory(preselectedCategory);
      const [level, id] = preselectedCategory.split('-');
      if (level !== undefined && id !== undefined) {
        setTimeout(() => {
          handleSelectCategory({
            id: Number(id),
            level: Number(level),
            label: '',
            value: preselectedCategory,
          });
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCategory = (selectedValue: {
    id: number;
    level: number;
    label: string;
    value: string;
  }) => {
    const filteredBrandLines = brandLines.filter((brandLine: Partial<DenormalizedBrandLine>) => {
      if (selectedValue.level === 1) {
        return brandLine.category1Id === selectedValue.id;
      }
      if (selectedValue.level === 2) {
        return brandLine.category2Id === selectedValue.id;
      }
      return true;
    });

    const brandLinesForTable = convertNewToOldBrandLines(
      filteredBrandLines,
      allOrigins,
      allBrands,
      allOwners,
      categoriesLevel5
    );

    setBrandLinesForSelectedCategory(brandLinesForTable);
    setSelectedCategory(selectedValue.value);
  };

  const brandLineFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterBrandLineName(e.target.value);
  };

  const originFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterOrigin(e.target.value);
  };

  const ownerFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterOwner(e.target.value);
  };

  const [filterBrandLineName, setFilterBrandLineName] = useState<string | undefined>('');
  const [filterOrigin, setFilterOrigin] = useState<string | undefined>('');
  const [filterOwner, setFilterOwner] = useState<string | undefined>('');

  const filteredBrandLines = getFilteredBrandLines({
    brandLines: brandLinesForSelectedCategory,
    brandLineName: filterBrandLineName,
    origin: filterOrigin,
    owner: filterOwner,
  });

  const filteredCount = filteredBrandLines.length;

  const maxRowToShow = 100;
  const showBrandLines = filteredCount < maxRowToShow && filteredCount > 0;
  const showWarning = !showBrandLines && filteredBrandLines.length !== 0;
  const showSelected = selected.length > 0;
  const disableNext = !showSelected;

  const duplicatedBrandLineGUIDs = getDuplicatedBrandLineGUIDs(allBrandSales, selected);
  const showDuplicateWarning = duplicatedBrandLineGUIDs.length;

  const handleNext = () => {
    if (showDuplicateWarning) {
      const contineToStep2 = confirm(
        'Some brand lines in your list already have sales. Are you sure you want to contine?'
      );
      if (!contineToStep2) {
        return;
      }
    }
    dispatch(goToStep2());
  };

  const handleShowCreate = () => {
    dispatch(goToCreate());
  };

  return (
    <>
      <Container>
        <Modal.Body
          style={{
            minHeight: `${modalHeight - 200}px`,
            maxHeight: `${modalHeight - 200}px`,
            display: 'flex',
            flexDirection: 'column',
          }}
          data-testid="step_one_brand_line_modal_body"
        >
          <Heading>Step 1. Select brand line(s) to add</Heading>
          <Form.Row>
            <Col md={4}>
              <Form.Group>
                <Label>Category</Label>
                <Dropdown
                  placeholder={SELECT_CATEGORY_PLACEHOLDER}
                  options={[{ options: options ?? [] }]}
                  option={SectionDropdownOption}
                  onSelect={handleSelectCategory}
                  selected={selectedCategory}
                  id="step_one_select_category_dropdown"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Label>Brand line</Label>
                <ValidTextInput
                  textEntryEnum={TextEntryEnum.BrandLineName}
                  onChange={brandLineFilterChange}
                  value={filterBrandLineName}
                  data-testid="step_one_brand_line"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Label>Origin</Label>
                <ValidTextInput
                  textEntryEnum={TextEntryEnum.OriginName}
                  onChange={originFilterChange}
                  value={filterOrigin}
                  data-testid="step_one_origin"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Label>Owner</Label>
                <ValidTextInput
                  textEntryEnum={TextEntryEnum.OwnerName}
                  onChange={ownerFilterChange}
                  value={filterOwner}
                  data-testid="step_one_owner"
                />
              </Form.Group>
            </Col>
          </Form.Row>

          {!selectedCategory && <div className="mt-2">Select a section</div>}

          {(showWarning || showBrandLines) && (
            <div className="mt-2">
              Matches: &nbsp;
              {filteredCount}/{brandLinesForSelectedCategory.length}
            </div>
          )}

          {showWarning && (
            <WarningDiv>Too many matches to show. Refine the filters above.</WarningDiv>
          )}
          <Box
            display="grid"
            overflow="auto"
            gridTemplateAreas={`
            "brand-lines-matches brand-lines-matches brand-lines-matches brand-lines-matches"
            "your-list-header your-list-header your-list-header your-list-header"
            "your-list your-list your-list your-list"
            `}
          >
            {showBrandLines && (
              <BrandLineTable
                brandLines={filteredBrandLines}
                isSelected={false}
                containerStyles={{ gridArea: 'brand-lines-matches' }}
              />
            )}

            {showSelected && (
              <>
                <Typography
                  variant="h3"
                  marginTop={pxToRem('12px')}
                  marginBottom={pxToRem('8px')}
                  fontSize="12pt"
                  fontWeight="bold"
                  gridArea="your-list-header"
                >
                  Your list
                </Typography>
                <BrandLineTable
                  brandLines={selected}
                  duplicatedBrandLineGUIDs={duplicatedBrandLineGUIDs}
                  isSelected
                  containerStyles={{
                    gridArea: 'your-list',
                    gridColumn: '1 / span 4',
                    gridRow: '3 / span 1',
                  }}
                />
              </>
            )}
          </Box>
          <div>
            Brand not found?{' '}
            <Button
              type="button"
              data-testid="step_one_create_new_brand"
              variant="link"
              onClick={handleShowCreate}
            >
              Create new brand
            </Button>
          </div>
        </Modal.Body>
      </Container>
      <Modal.Footer>
        <Button type="button" data-testid="step_one_cancel" variant="link" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          data-testid="step_one_step2"
          variant="primary"
          disabled={disableNext}
          onClick={handleNext}
        >
          Step 2: Add brand data
        </Button>
      </Modal.Footer>
    </>
  );
};

export default StepOne;
