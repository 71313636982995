import { set as setProperty } from 'dot-prop';

import type { unsafe_NewValueParams } from 'src/components/DataGrid';
import { COLLECTED_YEAR } from 'src/constants';
import type { Market } from 'src/markets';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { UseSyncContext } from 'src/sync/hooks';
import { type Entity } from 'src/types/Entity';
import isDeepEqual from 'src/utils/isDeepEqual';
import { getBooleanValueFromString, isStringifiedBooleanValue } from 'src/utils/string';
import type { Table } from 'src/views/types';
import { SALES_UPDATE_DIRECTLY_ON_AG_GRID } from '../constants/brandSalesTable';

export interface OnCellValueChangeHandlerParams
  extends Pick<
    unsafe_NewValueParams<DenormalizedBrandSales>,
    'api' | 'column' | 'data' | 'newValue' | 'oldValue'
  > {
  market: Market;
  table: Table;
  updateEntity: UseSyncContext['updateEntity'];
}

export const onCellValueChangeHandler = ({
  column,
  data,
  newValue,
  oldValue,
  table,
  updateEntity,
}: OnCellValueChangeHandlerParams) => {
  const areDifferentValues = !isDeepEqual(oldValue, newValue);

  if (areDifferentValues) {
    const entityGuid = String(data[table.primaryField]);
    const payloadValue = isStringifiedBooleanValue(newValue)
      ? getBooleanValueFromString(newValue)
      : (newValue as unknown);
    const payload: Partial<Entity> = setProperty({}, column.getColId(), payloadValue);

    if (
      column.getColId() === `volume.${COLLECTED_YEAR}` &&
      !data.acknowledgedByResearcher &&
      newValue != null
    ) {
      payload['acknowledgedByResearcher'] = true;
      void updateEntity(table.source, entityGuid, payload);
    } else {
      void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);
    }
  }
};
