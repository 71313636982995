export enum CharacterSetEnum {
  LIMITED = 'limited',
  EXTENDED = 'extended',
  FULL = 'full',
}

export enum TextEntryEnum {
  BrandName = 'brandName',
  BrandLineName = 'brandLineName',
  OwnerName = 'ownerName',
  OriginName = 'originName',
  CoverQuote = 'coverQuote',
  Extra = 'extra',
  Region = 'region',
  Variental = 'variental',
  Distributor = 'distributor',
  ApprovalReason = 'approvalReason',
}

export interface TextEntryItem {
  maxLength: number;
  characterSet: CharacterSetEnum;
}

type TextEntryItemList = Record<TextEntryEnum, TextEntryItem>;

export const UserTextEntry: TextEntryItemList = {
  [TextEntryEnum.BrandName]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.EXTENDED,
  },
  [TextEntryEnum.BrandLineName]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.EXTENDED,
  },
  [TextEntryEnum.OwnerName]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.OriginName]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.CoverQuote]: {
    maxLength: 140,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.Extra]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.Region]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.Variental]: {
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.Distributor]: {
    maxLength: 100,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [TextEntryEnum.ApprovalReason]: {
    maxLength: 140,
    characterSet: CharacterSetEnum.LIMITED,
  },
};

export enum NumberFormat {
  PERCENTAGE = 'percentange',
  CURRENCY = 'currency',
  INTEGER = 'integer',
  WITHTWODECIMALPLACES = 'with two decimal places',
  WITHTHREEDECIMALPLACES = 'with three decimal places',
  PERCENTAGENEGOK = 'percentage with optional negative',
}

interface NumberEntryItem {
  biggerThan?: number;
  biggerEqualThan?: number;
  lessThan?: number;
  lessEqualThan?: number;
  allowEstimates?: true;
  format: NumberFormat;
  allowNegatives?: boolean;
}

type NumberEntryItemList = Record<NumberEntryEnum, NumberEntryItem>;

export enum NumberEntryEnum {
  Price = 'price',
  DutyFreePrice = 'duty free prize',
  Volume = 'volume',
  RetailValue = 'retail value',
  BottleSize = 'bottle size',
  Age = 'age',
  ContainerSize = 'container size',
  PercentageNegOK = 'percentage with optional negative',
}

export const UserNumberEntry: NumberEntryItemList = {
  [NumberEntryEnum.Price]: {
    biggerThan: 0,
    format: NumberFormat.WITHTWODECIMALPLACES,
    allowEstimates: true,
  },
  [NumberEntryEnum.DutyFreePrice]: {
    biggerThan: 0,
    lessThan: 10000000000,
    format: NumberFormat.WITHTHREEDECIMALPLACES,
    allowEstimates: true,
  },
  [NumberEntryEnum.Volume]: {
    biggerEqualThan: 0,
    lessThan: 10000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [NumberEntryEnum.RetailValue]: {
    biggerEqualThan: 0,
    lessThan: 10000000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [NumberEntryEnum.BottleSize]: {
    biggerEqualThan: 1,
    lessEqualThan: 3000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [NumberEntryEnum.Age]: {
    biggerEqualThan: 0,
    lessEqualThan: 100,
    format: NumberFormat.INTEGER,
  },
  [NumberEntryEnum.ContainerSize]: {
    biggerEqualThan: 1,
    lessThan: 10000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [NumberEntryEnum.PercentageNegOK]: {
    biggerEqualThan: 0,
    lessEqualThan: 999,
    allowNegatives: true,
    format: NumberFormat.PERCENTAGENEGOK,
  },
};
