import type { ChangeEvent, FC } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useDroppable } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import Box from '@mui/material/Box';

import type { ColumnVisibilityModel } from 'src/components/DataGrid';
import { DEFAULT_NUMBER_OF_YEARS_TO_SHOW } from 'src/constants';
import type { Field, NumbersOfGroupedColumnsToShowModel } from '../../views/types';
import { SortableMenuItem } from '../SortableMenuItem';
import { NumberOfYearsToShowInput } from './NumberOfYearsToShowInput';

interface ColumnVisibilityMenuDropZoneProps {
  id: string;
  items: string[];
  allTableFields: Field[];
  columnVisibility: ColumnVisibilityModel | undefined;
  handleChange: (model: ChangeEvent<HTMLInputElement>) => void;
  disableChangingVisibility: boolean;
  disableChangingOrder: boolean;
  numbersOfGroupedColumnsToShow?: NumbersOfGroupedColumnsToShowModel | undefined;
  onNumbersOfGroupedColumnsToShowChange?:
    | ((model: NumbersOfGroupedColumnsToShowModel) => void)
    | undefined;
  enableNumbersOfGroupedColumnsToShowFeature?: boolean;
}

const ColumnVisibilityMenuDropZone: FC<ColumnVisibilityMenuDropZoneProps> = ({
  id,
  items,
  disableChangingOrder,
  allTableFields,
  disableChangingVisibility,
  columnVisibility = {},
  handleChange,
  numbersOfGroupedColumnsToShow,
  onNumbersOfGroupedColumnsToShowChange,
  enableNumbersOfGroupedColumnsToShowFeature,
}) => {
  const { setNodeRef } = useDroppable({ id });

  const fields = items.map((item: string) => {
    return allTableFields.find((field: Field) => field.field === item || field.groupName === item);
  });

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <ul className="droppable-zone" ref={setNodeRef}>
        <div className="droppable-zone-title">
          {id === 'pinnedLeft' ? 'Pinned Left' : id === 'pinnedRight' ? 'Pinned Right' : ''}
        </div>
        {fields.map(field => {
          if (field?.field == null) return null;

          const fieldNaming = field.groupName ?? field.field;
          const fieldLabel = field.groupName ?? field.headerName;

          return (
            <SortableMenuItem
              key={fieldNaming}
              id={fieldNaming}
              disabled={disableChangingOrder || field.primary === true}
              dataTestid={`column-visibility-menu-item`}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(disableChangingVisibility && { color: theme => theme.palette.grey['600'] }),
                }}
              >
                <FormCheck
                  checked={
                    // visible field is taken from columnVisibility saved in the state
                    // If not defined, check the defaul from column definition
                    // In both cases, undefined is treated as visible
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    (columnVisibility[fieldNaming] ?? field.visible !== false) ||
                    field.hideable === false
                  }
                  className="ml-2 my-2"
                  disabled={disableChangingVisibility || field.hideable === false}
                  label={<Box>{fieldLabel}</Box>}
                  name={fieldNaming}
                  onChange={handleChange}
                />
                {enableNumbersOfGroupedColumnsToShowFeature && 'periodise' in field ? (
                  <>
                    <Box
                      sx={{
                        ml: 0.5,
                        mr: 1,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      for last
                    </Box>
                    <NumberOfYearsToShowInput
                      initialValue={
                        numbersOfGroupedColumnsToShow?.[fieldNaming] ??
                        field.periodiseOptions?.numberOfYearsToShow ??
                        DEFAULT_NUMBER_OF_YEARS_TO_SHOW
                      }
                      onChange={(newValue: number) =>
                        onNumbersOfGroupedColumnsToShowChange?.({
                          ...numbersOfGroupedColumnsToShow,
                          [fieldNaming]: newValue,
                        })
                      }
                      readOnly={false}
                      fieldName={fieldNaming}
                    />
                    <Box sx={{ marginX: 1 }}>
                      year
                      {Number(
                        numbersOfGroupedColumnsToShow?.[fieldNaming] ??
                          field.periodiseOptions?.numberOfYearsToShow ??
                          DEFAULT_NUMBER_OF_YEARS_TO_SHOW
                      ) > 1
                        ? 's'
                        : ''}
                    </Box>
                  </>
                ) : null}
              </Box>
            </SortableMenuItem>
          );
        })}
      </ul>
    </SortableContext>
  );
};

export default ColumnVisibilityMenuDropZone;
