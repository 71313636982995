import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { set as setProperty } from 'dot-prop';

import { TopTotalsRow } from 'src/actions/TopTotalsRow';
import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_PostSortRowsParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { FilterMenu } from 'src/components/FilterMenu';
import { ForecastChartMenu, type ForecastChartMenuProps } from 'src/components/ForecastChartMenu';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { COLLECTED_YEAR } from 'src/constants';
import { Content } from 'src/css/styled-components';
import { isFeatureFlagSet } from 'src/feature-flags';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { useSyncContext } from 'src/sync';
import type { Entity, Volume } from 'src/types/Entity';
import { type ModelledForecastEntity } from 'src/types/entity/ModelledForecast';
import isDeepEqual from 'src/utils/isDeepEqual';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { SALES_UPDATE_DIRECTLY_ON_AG_GRID } from 'src/views/Pages/constants/brandSalesTable';
import AddForecast from 'src/views/Pages/ForecastsTableViewPage/AddForecast';
import {
  ApplyGrowthCalculationModal,
  type CalculationConfirmation,
} from 'src/views/Pages/ForecastsTableViewPage/ApplyGrowthCalculation/ApplyGrowthCalculation';
import {
  GrowthCalculationError,
  GrowthCalculationErrorType,
} from 'src/views/Pages/ForecastsTableViewPage/ApplyGrowthCalculation/GrowthCalculationError';
import {
  calculateAutomaticForecasts,
  calculateForecasts,
} from 'src/views/Pages/ForecastsTableViewPage/calculateForecasts';
import type { FlatCategoryItem } from 'src/views/types';
import {
  absoluteChange,
  compoundAnnualGrowthRate,
  relativeChange,
  sortUnknownRows,
} from 'src/views/utils';
import {
  ContextMenuActionType,
  createContextMenu,
  type GridContextMenuItem,
} from 'src/views/utils/contextmenu';
import {
  forecastsOnly,
  getColumnsWithSuffixedForecastYearHeaders,
} from 'src/views/utils/forecasting/grid';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { Chart, type SelectedCategoriesProps } from './Chart/Chart';
import { FORECAST_VIEW_COLUMN_VISIBILITY } from './constants';
import { dataSanityCheck } from './helper';
import { calculateModelledForecastTotals, calculatePreviousForecastTotals } from './helpers';
import { updateAgGridData } from './updateAgGridData';
import { getPrecisionFromVolume, hasMarketEditingPermissions } from './utils';

import 'src/css/grid.css';

const isExternalFilterPresent = () => true;

export const ForecastsTableViewPage: FC = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();

  const { convertFieldsToColumns } = useColumns();
  const [selectedNodeForChart, setSelectedNodeForChart] =
    useState<unsafe_RowNode<DenormalizedBrandSales>>();
  const [selectedRow, setSelectedRow] = useState<unsafe_RowNode<DenormalizedBrandSales>>();
  const [calculateGrowthModal, setCalculateGrowthModal] = useState(false);
  const [showAddForecastModal, setShowAddForecastModal] = useState(false);
  const [growthCalculationError, setGrowthCalculationError] = useState<GrowthCalculationErrorType>(
    GrowthCalculationErrorType.NONE
  );

  const [selectedNodeCategories, setSelectedNodeCategories] = useState<SelectedCategoriesProps>();

  const [forecastTotals, setForecastTotals] = useState<Volume>();
  const [modelledForecastTotals, setModelledForecastTotals] = useState<Volume>();
  const [previousForecastTotals, setPreviousForecastTotals] = useState<Volume>();

  const fields = table.fields;

  const [forecastChartMenuProps, setForecastChartMenuProps] = useState<ForecastChartMenuProps>({
    showForecastChart: true,
    showCurrentForecastInChart: true,
    showModelledForecastInChart: true,
    showPreviousForecastInChart: true,
    showSecondAxisInChart: true,
    secondAxisDataField: 'category2',
  });

  const view = useMemo(
    () => views.find(view => view.slug === viewSlug),
    // Don't depend on views. Each time we do useRecords we filter on all views
    // so receive a new object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );
  const section = useMemo(() => {
    return sections.find(section => section.id === view?.sectionId);
    // Don't depend on sections, we get a new object each time with useRecords
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.sectionId]);
  const brandSales = useRecords<DenormalizedBrandSales>(table.source, market.marketId);
  const modelledForecastData = useRecords<ModelledForecastEntity>(
    'modelledForecast',
    market.marketId
  );
  const previousForecastData = useRecords<DenormalizedBrandSales>(
    'previousForecast',
    market.marketId
  );

  const { columns, columnGrouping } = useMemo(
    () => {
      const columnsAndColumnGrouping = convertFieldsToColumns(fields, {
        periodiseByRows: false,
        lockedView: Boolean(view?.locked),
      });

      return {
        ...columnsAndColumnGrouping,
        columns: getColumnsWithSuffixedForecastYearHeaders(columnsAndColumnGrouping.columns),
      };
    },
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, view?.locked]
  );

  const forecastData = useMemo(() => {
    return calculateForecasts(brandSales);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(forecastData);

  const { handleColumnWidthsChange, state } = useGridViewState(table);

  const { key } = useDataGridUnmountKey({
    market,
    table,
    viewSlug,
  });

  const currentYear = COLLECTED_YEAR;
  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const categories = useRecords<FlatCategoryItem>('categories');
  const dataGridApiRef = useRef<unsafe_GridApi<DenormalizedBrandSales>>();

  const {
    updateEntity,
    onEntitiesReceived,
    onTransactionCreated,
    getEntitiesLookup,
    getEntities,
    getEntity,
  } = useSyncContext();

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived((data: Record<string, Entity[]>) => {
      updateAgGridData({
        data,
        getEntitiesLookup,
        getEntities,
        market,
        dataGridApiRef,
        getRowId,
        gridData: brandSales,
      });
    });
  });

  useEffect(() => {
    // After any transaction created, update AG-GRID data without re-rendering.
    return onTransactionCreated(data => {
      const { entities, transaction } = data;
      // in order to avoid updating brand sale volumes twice when any record is updated.
      if (transaction.actionType !== SALES_UPDATE_DIRECTLY_ON_AG_GRID) {
        updateAgGridData({
          data: { [transaction.entityType]: entities },
          getEntitiesLookup,
          getEntities,
          market,
          dataGridApiRef,
          getRowId,
          gridData: brandSales,
        });
      }
    });
  });

  useEffect(() => {
    setSelectedNodeForChart(undefined);
  }, [section?.name, view?.name]);

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const updatePinnedTopRowDataViaAPI = () => {
    const gridDataToCalculateFrom: DenormalizedBrandSales[] = [];

    dataGridApiRef.current?.forEachNodeAfterFilter(node => {
      if (node.data) {
        gridDataToCalculateFrom.push(node.data);
      }
    });

    const pinnedTotal = TopTotalsRow(
      market.marketName,
      section?.name ?? '',
      columns,
      gridDataToCalculateFrom
    );

    dataGridApiRef.current?.setGridOption('pinnedTopRowData', pinnedTotal);
    setForecastTotals(pinnedTotal?.[0].volume);
  };

  const handleCellEditingStopped = useCallback(
    () => {
      updatePinnedTopRowDataViaAPI();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );

  const handleCellValueChange = useCallback(
    ({ column, data, newValue, oldValue }: unsafe_NewValueParams<DenormalizedBrandSales>) => {
      const areDifferentValues = !isDeepEqual(oldValue, newValue);

      if (areDifferentValues) {
        const entityGuid = String(data[table.primaryField]);

        let newValueToPush = newValue as unknown;
        if (newValueToPush === 'true' || newValueToPush === 'false') {
          newValueToPush = String(newValue).toLowerCase() === 'true' ? true : false;
        }

        const payload = setProperty({}, column.getColId(), newValueToPush);
        void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);

        updatePinnedTopRowDataViaAPI();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const showCalculateGrowthModal = (node: unsafe_RowNode<DenormalizedBrandSales> | null) => {
    const sale = node?.data;

    if (sale) {
      if (!sale.volume[`${currentYear}`]) {
        setGrowthCalculationError(GrowthCalculationErrorType.NO_CURRENT_YEAR);
      } else if (!sale.volume[`${currentYear - 4}`]) {
        setGrowthCalculationError(GrowthCalculationErrorType.NO_HISTORIC_YEAR);
      } else {
        setSelectedRow(node);
        setCalculateGrowthModal(true);
      }
    }
  };

  const handleCloseCalculationGrowthModal = () => {
    setCalculateGrowthModal(false);
  };

  const closeGrowthCalculationErrorModal = () => {
    setGrowthCalculationError(GrowthCalculationErrorType.NONE);
  };

  const handleConfirmCalculation = ({
    calculation,
    finalVolume: finalValue,
    growthRate,
  }: CalculationConfirmation) => {
    setCalculateGrowthModal(false);
    const automatedFigures = calculateAutomaticForecasts({
      calculationType: calculation,
      finalValue,
      growthRate,
      currentYear,
      selectedNode: selectedRow?.data,
    });

    automatedFigures.forEach(automatedValue => {
      selectedRow?.setDataValue(`volume.${automatedValue.year}`, automatedValue.value);
    });
  };

  const formulaFunctions = useMemo(
    () => ({ compoundAnnualGrowthRate, relativeChange, absoluteChange }),
    []
  );

  const postSortRows = useCallback(
    ({ nodes }: unsafe_PostSortRowsParams<DenormalizedBrandSales>) => {
      view?.shouldSortUnknownRows && sortUnknownRows(nodes);
    },
    [view?.shouldSortUnknownRows]
  );

  const doesExternalFilterPass = useCallback(
    (rowNode: unsafe_RowNode<Entity>) => forecastsOnly(rowNode),
    []
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const getContextMenuItems = useCallback(
    (params: unsafe_GetContextMenuItemsParams<DenormalizedBrandSales>) => {
      if (!table.canWriteRecords) {
        return createContextMenu(table.canRemoveRecords, params, []);
      }

      const gridContextMenuItems: GridContextMenuItem[] = [
        {
          name: 'Add forecast',
          actionType: ContextMenuActionType.ADD,
          action: () => setShowAddForecastModal(true),
          tooltip: 'Add forecast',
        },
        {
          name: 'Calculate growth',
          actionType: ContextMenuActionType.ADD,
          action: () => showCalculateGrowthModal(params.node),
          disabled: (() => !params.node?.data)(),
        },
      ];

      if (isFeatureFlagSet('forecast-plan-b')) {
        const selectedRows = params.api.getSelectedRows();

        gridContextMenuItems.push({
          name: `Approve`,
          actionType: ContextMenuActionType.ACKNOWLEDGE,
          action: () => {
            selectedRows.forEach((row: DenormalizedBrandSales) => {
              void updateEntity('modelledForecast', row.saleGUID, {
                acknowledgedByResearcher: true,
              });
            });
          },
          tooltip: `Approve`,
          isMainMenuItem: true,
          disabled: selectedRows.length === 0,
        });
      }

      return createContextMenu(table.canRemoveRecords, params, gridContextMenuItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.source]
  );

  const handleGridReady = useCallback(
    (params: unsafe_GridReadyEvent) => {
      dataGridApiRef.current = params.api;

      updatePinnedTopRowDataViaAPI();

      const modelledForecastTotals = calculateModelledForecastTotals(
        forecastData,
        getEntity,
        view?.filter
      );

      const previousForecastTotals = calculatePreviousForecastTotals(
        previousForecastData,
        view?.filter
      );

      setModelledForecastTotals(modelledForecastTotals);
      setPreviousForecastTotals(previousForecastTotals);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section?.name, viewSlug]
  );

  const isCellEditable = useCallback(() => hasMarketEditingPermissions(market), [market]);

  const handleFilterChange = useCallback(
    () => {
      updatePinnedTopRowDataViaAPI();
      if (dataGridApiRef.current) {
        dataSanityCheck(dataGridApiRef.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRowChange = useCallback(
    ({ node }: { node: unsafe_RowNode<DenormalizedBrandSales> }) => {
      if (!node.group && node.data) {
        setSelectedNodeForChart(node);

        const { category5Id, category4Id, category3Id, category2Id } = node.data;
        const rowForecastByOrigin = categories.filter(
          category =>
            category.id === category5Id && category.level === 5 && category.forecastByOrigin
        );

        const isForecastByOrigin = rowForecastByOrigin[0]?.forecastByOrigin ?? false;
        setSelectedNodeCategories({
          category5Id,
          category4Id,
          category3Id,
          category2Id,
          isForecastByOrigin,
        });
      } else {
        setSelectedNodeForChart(undefined);
      }
    },
    // do not put categories in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const handleClearAllFilters = useCallback(() => {
    dataGridApiRef.current?.setFilterModel({});
  }, []);

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            pinnedColumns={{}}
            allTableFields={fields}
            columnVisibility={FORECAST_VIEW_COLUMN_VISIBILITY}
            onColumnOrderChange={() => ({})}
            onColumnVisibilityChange={() => ({})}
            onColumnPinnedChange={() => ({})}
            disableChangingVisibility
            disableChangingOrder
          />
          <FilterMenu onClearAllFilters={handleClearAllFilters} />
          <GroupingMenu
            columns={fields}
            disabled
            rowGrouping={view.rowGrouping}
            onRowGroupingChange={() => ({})}
          />

          <ForecastChartMenu
            {...forecastChartMenuProps}
            onChange={setForecastChartMenuProps}
            isForecastByOrigin={view.rowGrouping?.includes('originId') ?? false}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          {forecastChartMenuProps.showForecastChart && (
            <Chart
              currentYear={currentYear}
              forecasts={forecastData}
              forecastTotals={forecastTotals}
              modelledForecastData={modelledForecastData}
              modelledForecastTotals={modelledForecastTotals}
              precision={getPrecisionFromVolume(fields)}
              previousForecastData={previousForecastData}
              previousForecastTotals={previousForecastTotals}
              secondAxisField={forecastChartMenuProps.secondAxisDataField}
              selectedCategories={selectedNodeCategories}
              selectedGridRow={selectedNodeForChart}
              showCurrentYearForecast={forecastChartMenuProps.showCurrentForecastInChart}
              showModelledForecast={forecastChartMenuProps.showModelledForecastInChart}
              showPreviousForecast={forecastChartMenuProps.showPreviousForecastInChart}
              showSecondAxis={forecastChartMenuProps.showSecondAxisInChart}
              viewName={view.name}
            />
          )}
          <DataGrid<DenormalizedBrandSales>
            debug
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={false}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={false}
            columnGrouping={columnGrouping}
            columns={columns}
            columnVisibility={FORECAST_VIEW_COLUMN_VISIBILITY}
            columnWidths={state.columnWidths}
            filter={view.filter ?? []}
            filterIsViewDefined
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            isCellEditable={isCellEditable}
            onCellValueChange={handleCellValueChange}
            onCellEditingStopped={handleCellEditingStopped}
            onColumnWidthChange={handleColumnWidthsChange}
            onGridReady={handleGridReady}
            onFilterChange={handleFilterChange}
            getRowClass={getRowClass}
            rows={forecastData}
            rowGrouping={view.rowGrouping}
            sort={view.sort}
            postSortRows={postSortRows}
            getContextMenuItems={getContextMenuItems}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onRowClicked={handleRowChange}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
            {...(isFeatureFlagSet('forecast-plan-b') && { rowSelection: 'multiple' })}
          />
        </Content>
      </Container>

      {calculateGrowthModal && (
        <ApplyGrowthCalculationModal
          onCloseModal={handleCloseCalculationGrowthModal}
          onConfirmCalculation={handleConfirmCalculation}
        />
      )}

      {growthCalculationError !== GrowthCalculationErrorType.NONE && (
        <GrowthCalculationError
          error={growthCalculationError}
          currentYear={currentYear}
          closeModal={closeGrowthCalculationErrorModal}
        />
      )}

      {showAddForecastModal && <AddForecast closeModal={() => setShowAddForecastModal(false)} />}
    </>
  );
};
