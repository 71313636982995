import type {
  ColDef as BaseColDef,
  ColGroupDef as BaseColGroupDef,
  GridOptions as BaseGridOptions,
  IAggFuncParams,
} from '@ag-grid-community/core';

export interface BaseColumn {
  /**
   * If true, the cells of the column are aggregable.
   * @default false
   */
  aggregable?: boolean;

  /**
   * The description of the column rendered as tooltip.
   */
  description?: string;

  /**
   * If true, the cells of the column are editable.
   * @default false
   */
  editable?: boolean;

  /**
   * Whether to only show the column when the group is open / closed. If not set the column is always displayed as part of the group.
   * @default false
   */
  columnGroupShow?: 'open' | 'closed' | undefined;

  /**
   * Enables fill handle for this column.
   * @default false
   */
  enableFillHandle?: boolean;

  /**
   * The column identifier. It's used to map with the contents of a row.
   */
  field: string;

  /**
   * If true, the column is filterable.
   * @default true
   */
  filterable?: boolean;

  /**
   * If true, the rows can be grouped based on the column values.
   * @default false
   */
  groupable?: boolean;

  /**
   * The group name that field belongs to.
   */
  groupName?: string;

  /**
   * The title of the column rendered in the column header cell.
   */
  headerName: string;

  /**
   * If true, the column is hideable.
   * @default true
   */
  hideable?: boolean;

  /**
   * Set the initialWidth width of the column.
   * The user can override this in the UI if the column is resizable.
   * @default 100
   */
  initialWidth?: number;

  /**
   * When set to true, cells in this column can only be edited if the column is marked as `editable` and the cell's value is blank.
   * @default false
   */
  locked?: boolean;

  /**
   * Set the maximum width of the column.
   * @default Infinity
   */
  maxWidth?: number;

  /**
   * Set the minimum width of the column.
   * @default 50
   */
  minWidth?: number;

  /**
   * If true, the column can be dragged to a new position.
   * @default true
   */
  moveable?: boolean;

  /**
   * A configuration object used to configure the column.
   */
  options?: unknown;

  /**
   * If true, the column is pinnable.
   * @default true
   */
  pinnable?: boolean;

  /**
   * This is to mark the column as primary, which means it will be pinned to the left and it can not be moved or hidden.
   * @default false
   */
  primary?: boolean;

  /**
   * If true, the column is resizable.
   * @default true
   */
  resizable?: boolean;

  /**
   * If true, the column is sortable.
   * @default true
   */
  sortable?: boolean;

  /** Component for rendering the tooltip. */
  Tooltip?: () => JSX.Element;

  /** Whether to show a tooltip. */
  tooltip?: true;

  /** Options to apply to the tooltip. */
  tooltipOptions?: {
    /** List of objects containing key/value pairs representing Record field names, e.g. `brandLineDisplayName` in brand lines. */
    fields: {
      /** Name of the Record field. */
      field: string;
      /** Friendly name for the Record field. */
      friendlyName: string;
    }[];
  };

  /**
   * The type of content displayed in the column.
   */
  type: string;

  /**
   * If true, the column is visible by default.
   * The user can override in the UI in case the column is hideable
   * @default true
   */
  visible?: boolean;
}

interface NumberColumnOptions {
  /**
   * The currency to use in currency formatting.
   */
  currency?: string;

  /**
   * The formatting style to use.
   * @default decimal
   */
  format?: 'currency' | 'decimal' | 'percent';

  /**
   * The number of decimal places to use.
   * @default 2
   */
  precision?: number;
  /*
  The minimum value to accept for this input. If the cell's value is less than this, constraint validation fails.
  If a value is specified for min that isn’t a valid number, the input has no minimum value
  */
  min?: number;
  /*
  The maximum value to accept for this input. If the value entered into the cell exceeds this, constraint validation fails.
  If the value of the max attribute isn’t a number, then the field has no maximum value.
  */
  max?: number;
}

export interface NumberColumn extends BaseColumn {
  /**
   * If true, the cells of the column are estimatable.
   * @default false
   */
  estimatable?: boolean;

  options?: NumberColumnOptions;
  type: 'number';
}

interface Choice {
  /** Human-readable text for the choice. */
  label: string;

  /** Machine value of the choice. */
  value: number | string | boolean;

  /** Whether to hide this choice in the agSelectCellEditor */
  hideInEditor?: boolean;
}

interface SelectColumnOptions {
  /** A list of choices to choose from. */
  choices: Choice[];

  /** The type of editor to bring up when editing a cell. Currently only option is 'dialog',
   *  otherwise, if not passed in, it will use AG Grid's agSelectCellEditor   */
  editor?: 'dialog';
}

export interface SelectColumn extends BaseColumn {
  options: SelectColumnOptions;
  type: 'select';
}

export interface EntityColumnOptions {
  /** Type of entity the select is based on */
  entityType: string;

  /** field to use as the value for the choice */
  valueField: string;

  /** Label field to use as label for the choice */
  labelField: string;

  /* Field to use for display order of the choices */
  orderField?: string;

  /** A list of choices to choose from. */
  choices: (Choice & {
    /** Order to display the options in. */
    displayOrder?: number;
  })[];

  /** The type of editor to bring up when editing a cell. Currently only option is 'dialog',
   *  otherwise, if not passed in, it will use AG Grid's agSelectCellEditor   */
  editor?: 'dialog';
}

export interface EntityColumn extends BaseColumn {
  options: EntityColumnOptions;
  type: 'entity';
}

interface TextColumnOptions {
  /*
    When defined, contains the characters allowed to be used in the field.
    If a value containing a character not in here is tried to be set, the value
    is ignored and the content of the field isn't changed.
  */
  allowedCharacters?: string;

  /** Indicates that the cell usually contains a lot of text, so setting to true will use the 'agLargeTextCellEditor' */
  largeText?: boolean;

  /** Max number of characters to allow. Default: 200 */
  maxLength?: number;
}

export interface TextColumn extends BaseColumn {
  options?: TextColumnOptions;
  type: 'text';
}

interface FormulaColumnOptions extends NumberColumnOptions {
  /** The formula expression to use. */
  formula: string;
}

export interface FormulaColumn extends BaseColumn {
  options: FormulaColumnOptions;
  type: 'formula';
}

export interface LinkedRecordColumn extends BaseColumn {
  options?: TextColumnOptions;
  type: 'linkedRecord';
}

export type Column =
  | FormulaColumn
  | LinkedRecordColumn
  | NumberColumn
  | SelectColumn
  | TextColumn
  | EntityColumn;

interface LeafColumn {
  field: string;
}

export interface ColumnGroup extends Pick<BaseColumn, 'description' | 'headerName'> {
  /**
   * The columns included in this group.
   */
  children: LeafColumn[];

  /**
   * A unique string identifying the group.
   */
  groupId: string;
}

/**
 * The key-value object representing the data of a row.
 */
export type Row<R extends Record<string, unknown> = Record<string, unknown>> = R;

export type AggregationModel = Record<string, string>;
export type ColumnGroupingModel = ColumnGroup[];
export type ColumnOrderModel = string[];
export type ColumnVisibilityModel = Record<string, boolean>;
export type ColumnWidthModel = Record<string, number>;
export enum FilterOperator {
  Equals = 'equals',
  NotEqual = 'notEqual',
  Contains = 'contains',
  NotContains = 'notContains',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  Blank = 'blank',
  NotBlank = 'notBlank',
  ChooseOne = 'empty',
  IsAnyOf = 'isAnyOf',
}
export interface FilterItem {
  /** The field from which we want to filter the rows. */
  field: string;

  /** The name of the operator we want to apply. */
  operator: FilterOperator | keyof typeof FilterOperator;

  /** The filtering value. */
  value?: unknown;
}
export type FilterModel = FilterItem[];
export interface GroupingColumn {
  /** The field from which we want to render the leaves. */
  leafField?: string;
}
export interface PinnedColumnsModel {
  /** The columns to display pinned to left. */
  left?: string[];

  /** The columns to display pinned to right. */
  right?: string[];
}
export type RowGroupingModel = string[];
export type SortDirection = 'asc' | 'desc';
export interface SortItem {
  /**
   * The column field identifier.
   */
  field: string;

  /**
   * The direction of the column that the grid should sort.
   */
  sort: SortDirection;
}
export type SortModel = SortItem[];

export type ColDef<R extends Row> = Omit<BaseColDef<R>, 'field' | 'headerName'> &
  Required<Pick<BaseColDef<R>, 'field' | 'headerName'>>;

export type ColGroupDef<R extends Row> = Omit<
  BaseColGroupDef<R>,
  'children' | 'groupId' | 'headerName'
> &
  Required<Pick<BaseColGroupDef<R>, 'groupId' | 'headerName'>> & {
    children: ColDef<R>[];
  };

export type ColTypeDef<R extends Row> = Omit<BaseColDef<R>, 'field' | 'filterParams'> & {
  filterParams: {
    filterOptions: FilterOperator[];
    [key: string]: unknown;
  };
};

export interface EstimatedValue {
  isEstimated: boolean;
  value: unknown;
}

export type AggregationFunction = (params: IAggFuncParams) => number | null | undefined;

export type FormulaFunction = (...args: unknown[]) => number | null | undefined;

export type GridOptions<R extends Row> = Omit<
  BaseGridOptions<R>,
  'columnDefs' | 'columnTypes' | 'context' | 'defaultColDef'
> &
  Required<Pick<BaseGridOptions<R>, 'defaultColDef'>> & {
    columnDefs?: (ColDef<R> | ColGroupDef<R>)[];
    columnTypes: Record<string, ColTypeDef<R>>;
    context: {
      formulaFunctions: Record<string, FormulaFunction>;
    };
  };

export type NumberCellValue = { isEstimated: boolean; value: number } | number | undefined | null;
