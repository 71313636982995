import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// @TODO TODO: - remove these legacy imports
import { Button, Modal, Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { set as setProperty } from 'dot-prop';
import { v4 as uuidv4 } from 'uuid';

import type { SubcategoriesTreeOption } from 'src/components/BrandSales/AddBrandLine/utils/stepOne';
import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_PostSortRowsParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { Dropdown } from 'src/components/Dropdown';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { COLLECTED_YEAR } from 'src/constants';
import { Content, ModalHeader, SectionDropdownOption } from 'src/css/styled-components';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import { denormalizeEcommerceDataUtil } from 'src/records/utils/denormalizers';
import { type Transaction, useSyncContext } from 'src/sync';
import type { Entity } from 'src/types/Entity';
import type { CategoriesEntity } from 'src/types/entity/Categories';
import isDeepEqual from 'src/utils/isDeepEqual';
import { getBooleanValueFromString, isStringifiedBooleanValue } from 'src/utils/string';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { absoluteChange, sortUnknownRows } from 'src/views/utils';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { type PopupConfiguration, PopupType } from '../common/types';
import type { GroupedOption } from '../utils/ecommerce';
import createContextMenu from './getContextMenuItems';

import 'src/css/grid.css';

const SELECT_CATEGORY_OPTION_PLACEHOLDER = 'Select Category';

const getEcommerceTotals = (dataGridApiRef: React.MutableRefObject<unsafe_GridApi | undefined>) => {
  const ecommerceData: Entity[] = [];

  dataGridApiRef.current?.forEachNode((node: { data?: Entity }) => {
    if (node.data) {
      ecommerceData.push(node.data);
    }
  });

  const yearTotals: Record<string, number> = {};

  for (const ecommerce of ecommerceData) {
    const values = ecommerce['retailValue'] as Record<string, undefined | number>;

    /* TODO: 2000 should come from field periodisedOptions */
    for (let year = 2000; year <= COLLECTED_YEAR; year += 1) {
      const yearData = values[year];

      if (yearData && yearData > 0) {
        if (yearTotals[year] !== undefined) {
          yearTotals[year] += yearData;
        } else {
          yearTotals[year] = yearData;
        }
      }
    }
  }

  return yearTotals;
};

const isExternalFilterPresent = () => true;

export const EcommerceTableViewPage: FC = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();

  const dataGridApiRef = useRef<unsafe_GridApi>();

  const SALES_UPDATE_DIRECTLY_ON_AG_GRID = 'SalesUpdateDirectlyOnAgGrid';

  const {
    updateEntity,
    deleteEntity,
    createEntity,
    onEntitiesReceived,
    onTransactionCreated,
    getEntitiesLookup,
  } = useSyncContext();

  const entitiesLookup = getEntitiesLookup();
  const updateAgGridData = (data: Record<string, Entity[]>) => {
    // get only this market data from provider.
    const ecommerceData =
      data[table.source]?.filter(item => item['countryId'] === market.marketId) ?? [];

    let selectedNode: unsafe_RowNode | undefined;

    ecommerceData.forEach(ecommerceEntity => {
      // extra control for skipping sales that does not belong to this market.
      if (ecommerceEntity['countryId'] !== market.marketId) {
        return;
      }

      dataGridApiRef.current?.forEachNode(node => {
        if (node.data && (node.data as Entity)[getRowId()] === ecommerceEntity[getRowId()]) {
          selectedNode = node;
          return;
        }
      });

      if (selectedNode) {
        // if comming brand sale has isDeleted flag, then remove the item from the grid.
        if (ecommerceEntity['isDeleted']) {
          dataGridApiRef.current?.applyTransaction({ remove: [selectedNode.data] });
        } else {
          // update the existing ag grid wine colour sale item.
          selectedNode.data = denormalizeEcommerceDataUtil(entitiesLookup, {
            ...ecommerceEntity,
          });
          dataGridApiRef.current?.applyTransaction({
            update: [selectedNode.data as Entity],
          });
        }
      } else {
        const dataToBeAdded: Entity[] = [
          {
            ...denormalizeEcommerceDataUtil(entitiesLookup, {
              ...ecommerceEntity,
            }),
          },
        ];

        dataGridApiRef.current?.forEachNode(node => {
          if (node.data) {
            dataToBeAdded.push({
              ...denormalizeEcommerceDataUtil(entitiesLookup, {
                ...(node.data as Entity),
              }),
            });
            dataGridApiRef.current?.applyTransaction({ remove: [node.data] });
            return;
          }
        });

        // if coming brand sale does not exist, add it to the grid.
        dataGridApiRef.current?.applyTransaction({
          add: dataToBeAdded,
        });
      }
      dataGridApiRef.current?.setGridOption('pinnedTopRowData', [
        {
          category2Id: 'Sales Total',
          retailValue: getEcommerceTotals(dataGridApiRef),
        },
      ]);
    });
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived((data: Record<string, Entity[]>) => {
      updateAgGridData(data);
    });
  });

  useEffect(() => {
    // After any transaction created, update AG-GRID data without re-rendering.
    return onTransactionCreated((data: { entities: Entity[]; transaction: Transaction }) => {
      const { entities, transaction } = data;
      // in order to avoid updating brand sale volumes twice when any record is updated.
      if (transaction.actionType !== SALES_UPDATE_DIRECTLY_ON_AG_GRID) {
        updateAgGridData({ [transaction.entityType]: entities });
      }
    });
  });

  const [popupConfiguration, setPopupConfiguration] = useState<PopupConfiguration | undefined>(
    undefined
  );

  const view = useMemo(
    () => views.find(view => view.slug === viewSlug),
    // Don't depend on views. Each time we do useRecords we filter on all views
    // so receive a new object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );
  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const fields = useMemo(() => {
    return table.fields;
  }, [table.fields]);

  const section = useMemo(() => {
    return sections.find(section => section.id === view?.sectionId);
    // Don't depend on sections, we get a new object each time with useRecords
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.sectionId]);

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const gridData = useRecords(table.source, market.marketId);
  const categories = useRecords<CategoriesEntity>('categories');
  const [ecommerceCategoriesOptions, setEcommerceCategoriesOptions] = useState<GroupedOption[]>([]);

  const [selectedCategoryOptionId, setSelectedCategoryOptionId] = useState<number | string>();

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () =>
      convertFieldsToColumns(fields, {
        periodiseByRows: false,
        lockedView: Boolean(view?.locked),
      }),
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, view?.name, view?.locked]
  );
  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    state,
  } = useGridViewState(table);

  const { key } = useDataGridUnmountKey({ market });

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view?.pinnedColumns, state]
  );

  const handleCellValueChange = useCallback(
    ({ column, data, newValue, oldValue }: unsafe_NewValueParams<Entity>) => {
      const hasDifferentValue = !isDeepEqual(oldValue, newValue);

      if (hasDifferentValue) {
        const entityGuid = String(data[table.primaryField]);
        const payloadValue = isStringifiedBooleanValue(newValue)
          ? getBooleanValueFromString(newValue)
          : (newValue as unknown);
        const payload = setProperty({}, column.getColId(), payloadValue);

        dataGridApiRef.current?.setGridOption('pinnedTopRowData', [
          {
            category2Id: 'Sales Total',
            retailValue: getEcommerceTotals(dataGridApiRef),
          },
        ]);

        void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const postSortRows = useCallback(
    ({ nodes }: unsafe_PostSortRowsParams<Entity>) => {
      view?.shouldSortUnknownRows && sortUnknownRows(nodes);
    },
    [view?.shouldSortUnknownRows]
  );

  const doesExternalFilterPass = () => true;

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const getContextMenuItems = (params: unsafe_GetContextMenuItemsParams<Entity>) =>
    createContextMenu(
      params,
      table.canWriteRecords,
      table.canRemoveRecords,
      categories,
      setEcommerceCategoriesOptions,
      setPopupConfiguration,
      dataGridApiRef
    );

  const formulaFunctions = useMemo(() => ({ absoluteChange }), []);

  const handleGridReady = useCallback(
    (params: unsafe_GridReadyEvent) => {
      dataGridApiRef.current = params.api;
      dataGridApiRef.current.setGridOption('pinnedTopRowData', [
        {
          category2Id: 'Sales Total',
          retailValue: getEcommerceTotals(dataGridApiRef),
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section?.name, gridData, viewSlug]
  );

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const deleteEcommerce = () => {
    const entityGuid = popupConfiguration?.selectedItem[getRowId()] as string;
    if (entityGuid) {
      void deleteEntity(table.source, entityGuid);
    }

    setPopupConfiguration(undefined);
  };

  const addEcommerce = () => {
    if (selectedCategoryOptionId) {
      const newVarietal: Entity = {
        ecommerceGUID: `${uuidv4().toUpperCase()}`,
        countryId: market.marketId,
        category2Id: selectedCategoryOptionId,
        retailValue: {},
      };

      void createEntity(table.source, newVarietal);
    }

    setPopupConfiguration(undefined);
    setSelectedCategoryOptionId(undefined);
  };

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            disableChangingVisibility={!!view.locked}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <GroupingMenu
            columns={fields}
            disabled={!table.canChangeRowGrouping || (view.locked ?? false)}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<Entity>
            debug
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            filter={view.filter ?? []}
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            onCellValueChange={handleCellValueChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            onGridReady={handleGridReady}
            pinnedColumns={pinnedColumns}
            getRowClass={getRowClass}
            rows={gridData}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            postSortRows={postSortRows}
            getContextMenuItems={getContextMenuItems}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
          />
        </Content>

        <Modal
          show={popupConfiguration?.popupType === PopupType.DeleteWarning}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="ecommerce_modal">
            <>Delete: {popupConfiguration?.selectedItem['category2Name']}</>
          </ModalHeader>
          <Modal.Body>You can not delete this row because it has volume data</Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="link" onClick={() => setPopupConfiguration(undefined)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={popupConfiguration?.popupType === PopupType.DeleteConfirmation}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="ecommerce_modal">
            <>Delete: {popupConfiguration?.selectedItem['category2Name']}</>
          </ModalHeader>
          <Modal.Body>
            {' '}
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <>Are you sure you want to delete this row?</>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="link" onClick={() => setPopupConfiguration(undefined)}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={deleteEcommerce}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={popupConfiguration?.popupType === PopupType.AddNewItem}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="ecommerce_modal">Add new ecommerce</ModalHeader>
          <Modal.Body>
            <Dropdown
              groupedOptions
              options={ecommerceCategoriesOptions}
              option={SectionDropdownOption}
              selected={selectedCategoryOptionId}
              placeholder={SELECT_CATEGORY_OPTION_PLACEHOLDER}
              onSelect={({ id }: SubcategoriesTreeOption) => setSelectedCategoryOptionId(id)}
              id="ecommerce_select_category_dropdown"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="link" onClick={() => setPopupConfiguration(undefined)}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={addEcommerce}
              disabled={!selectedCategoryOptionId}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
