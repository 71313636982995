import { entityConfiguration, syncingConfig } from 'src/sync/config';
import { AppException } from 'src/types/AppException';
import { type EntityVersion } from 'src/types/entity/EntityVersion';
import { findAndReplace } from 'src/utils/funcUtils';
import type { Entity } from '../../types/Entity';
import { createObservable, isApiServiceAvailable } from '../functions';
import { doFetchRequest } from '../functions/request';
import {
  type ApiError,
  type ApiResponse,
  BootstrapType,
  BroadcastMessageType,
  type CachedMarketsItem,
  type EntityMetadata,
  type EntitySchema,
  EntitySyncState,
  type FetchDataResponse,
  type FetchEntitiesRequest,
  type FetchEntitiesResponse,
  type FetchEntityResponse,
  HttpStatusCode,
  type PaginatedData,
  SyncManagerState,
  TransactionType,
} from '../types';
import type { BroadcastManager } from './broadcast-manager';
import type { CachingManager } from './caching-manager';
import type { LocalDbManager } from './localdb-manager';
import { type QueueManager } from './queue-manager';

export class SyncManager {
  public status: SyncManagerState = SyncManagerState.Success;
  public syncingPercentage = 0;

  private FETCH_REQUEST_RETRY_COUNT = 3;
  private CACHED_MARKET_IDS = 'cached-market-ids';
  private CACHED_MARKET_EXPIRY_TIME_HRS = Number(
    window.localStorage.getItem('CACHED_MARKET_EXPIRY_TIME_HRS') ?? 72
  );
  private numberOfSucceedSyncEntities = 0;
  private LAST_VISITED_MARKET = 'lastVisitedMarket';

  /**
   * An observable to monitor changes in the status of the market data change.
   */
  public readonly onMarketDataChange = createObservable<Entity[]>();

  /**
   * An observable to monitor changes in the status of sync manager state change.
   */
  public readonly onSyncManagerStateChange = createObservable<SyncManagerState>();

  /**
   * An observable to monitor changes in the status of sync manager percentage change.
   */
  public readonly onSyncPercentageChange = createObservable<number>();

  /**
   * An observable to provide new receiving entities, data can be provided by deltasync on local or deltasync on other tabs.
   */
  public readonly onEntitiesReceived = createObservable<Record<string, Entity[]>>();

  constructor(
    private readonly localDbManager: LocalDbManager,
    private readonly broadcastManager: BroadcastManager,
    private readonly cachingManager: CachingManager,
    private readonly queueManager: QueueManager
  ) {
    this.broadcastManager.onSyncManagerStateChange.subscribe((status: SyncManagerState) =>
      this.setSyncMagerState(status)
    );
    this.broadcastManager.onSyncPercentageChange.subscribe((percentage: number) => {
      this.setSyncingPercentage(percentage);
    });
    this.broadcastManager.onEntitiesReceived.subscribe((data: Record<string, Entity[]>) => {
      this.cachingManager.cacheEntities(data);
      this.onEntitiesReceived.notify(data);
    });
  }

  isAdminPage(): boolean {
    return window.location.pathname.startsWith('/admin');
  }

  async checkApiCallAvailability() {
    if (!navigator.onLine) {
      return false;
    }
    const isServiceAvailable = await isApiServiceAvailable();
    return isServiceAvailable;
  }

  /**
   * Syncing bootstrap, which should be called when user is signed in or page is refreshed.
   */
  async syncData(): Promise<{ error?: Error | undefined; status: SyncManagerState }> {
    let error: Error | undefined;

    const bootstrapType = await this.getBootstrapType();

    if (bootstrapType === BootstrapType.Full || bootstrapType === BootstrapType.Partial) {
      // This means the researcher has missing data. To continue using the application,all the missing data must be retrieved.
      // That’s why the UI must wait for this operation to be completed.
      error = await this.doBootstrapSync();
    } else {
      // It should be done in a backend thread to operate independently from the UI;
      // that's why `await` is not used.
      void this.doDeltaSync();
    }

    return {
      error,
      status: this.status,
    };
  }

  async doBootstrapSync(): Promise<Error | undefined> {
    const isApiCallAvailable = await this.checkApiCallAvailability();

    // If Bootstrap is required, it means the user has missing data, so all necessary data should be pulled.
    // If the API call is unavailable, the user should not be allowed to use the application
    if (!isApiCallAvailable) {
      throw Error(
        `There is missing data in your local browser database. Please connect to the internet to allow Bootstrap to pull in the required data. If you are already connected, please contact the IT team for assistance.`
      );
    }

    await this.checkEntitiesVersion();

    this.setSyncMagerState(SyncManagerState.InProgress);

    await this.resetPreviouslyUnsuccessfulEntities();

    this.numberOfSucceedSyncEntities = 0;
    this.broadcastSyncPercentageChange();

    // Fetch markets (/meta/markets) (and entity configuration data)
    const fetchMarketResult = await this.fetchMarkets();

    if (fetchMarketResult.entityMetaData.status === EntitySyncState.Success) {
      const assignedMarketIds = await this.getAssignedMarketIds();

      // get already cached marketIds and new coming assigned market(s) during full or partial bootstrap
      const cachedAndNewAssignedMarketIds = [
        ...new Set([...this.getCachedMarketIds(), ...assignedMarketIds]),
      ];

      // Fetch all entities (based on markets)
      const fetchEntitiesResponse = await this.fetchEntities({
        marketIds: cachedAndNewAssignedMarketIds,
        isBootstrap: true,
      });

      if (fetchEntitiesResponse.unSuccesfulFetchedEntities.length > 0) {
        this.setSyncMagerState(SyncManagerState.Failed);
        throw Error(
          `Some entities could not be fetched: ${fetchEntitiesResponse.unSuccesfulFetchedEntities
            .map(({ entityType }) => entityType)
            .join(', ')}`
        );
      }
      this.cacheMarketIds(assignedMarketIds);
      this.setSyncMagerState(SyncManagerState.Success);
    } else {
      this.setSyncMagerState(SyncManagerState.Failed);
      throw Error('Could not fetch markets during bootstrap!');
    }

    return;
  }

  /**
   * Performs delta syncing.
   */
  async doDeltaSync() {
    const isApiCallAvailable = await this.checkApiCallAvailability();

    // Check if there is delta syncing in progress on other tab
    // if yes, skipping doing deltasyncing.
    if (!isApiCallAvailable || this.isAdminPage() || this.status === SyncManagerState.InProgress) {
      return;
    }

    await this.checkEntitiesVersion();

    this.numberOfSucceedSyncEntities = 0;
    this.broadcastSyncPercentageChange();
    this.setSyncMagerState(SyncManagerState.InProgress);
    this.broadcastSyncManagerStateChange();
    const fetchMarketsResponse = await this.fetchMarkets();

    // Get all assigned markets
    const assignedMarketIds = await this.getAssignedMarketIds();
    const cachedMarkets = this.getCachedMarketIds();
    this.broadcastSyncPercentageChange();

    // Check if new market is assigned during the active session.
    // if new market is assigned fetch its data (brandsale, markets, tables, etc..)
    for (const marketId of assignedMarketIds) {
      if (!this.isMarketAlreadyCached(marketId)) {
        const fetchEntitiesResponse = await this.fetchEntities({
          marketIds: [marketId],
          fetchMarketEntitiesOnly: true,
        });

        if (fetchEntitiesResponse.unSuccesfulFetchedEntities.length > 0) {
          throw Error(
            `Could not fetch specific entities during delta syncing: ${fetchEntitiesResponse.unSuccesfulFetchedEntities
              .map(({ entityType }) => entityType)
              .join(', ')}`
          );
        }
      }
    }

    // get already cached marketIds and new coming assigned market(s) during delta sync.
    const cachedAndNewAssignedMarketIds = [...new Set([...cachedMarkets, ...assignedMarketIds])];

    // fetch all entities with the cached market and new coming assigned market(s).
    const fetchEntitiesResponse = await this.fetchEntities({
      marketIds: cachedAndNewAssignedMarketIds,
    });

    if (
      fetchEntitiesResponse.unSuccesfulFetchedEntities.find(
        item => item.status === EntitySyncState.TimedOut
      )
    ) {
      this.setSyncMagerState(SyncManagerState.TimedOut);
    } else if (fetchEntitiesResponse.unSuccesfulFetchedEntities.length > 0) {
      this.setSyncMagerState(SyncManagerState.Failed);
    } else {
      // when cache completes put new assigned market(s) to the local cache storage.
      this.cacheMarketIds(assignedMarketIds);

      // notify observer for receiving new items, required for AG-GRID especially...
      this.onEntitiesReceived.notify(fetchEntitiesResponse.fetchedEntities);
      this.broadcastReceivedEntities(fetchEntitiesResponse.fetchedEntities);

      if (fetchMarketsResponse.entities.length > 0) {
        this.onMarketDataChange.notify(this.cachingManager.getEntities('markets'));
      }

      this.setSyncMagerState(SyncManagerState.Success);
    }
    this.broadcastSyncManagerStateChange();
  }

  /**
   * Temporary function to migrate the CACHED_MARKET_IDS array
   *  from:  [1,2,3]
   *  to: [{ id: 1, lastAccessedDate: '2024-11-11' }, { id: 2, lastAccessedDate: '2024-11-11' }, { id: 3, lastAccessedDate: '2024-11-11'}]
   */
  private migrateCachedMarketIds(array: (number | CachedMarketsItem)[]) {
    return array.map(item => {
      if (typeof item === 'number') {
        return { id: item, lastAccessedDate: new Date().toISOString() };
      }
      return item;
    });
  }

  private removeExpiredCachedMarkets(cachedMarkets: CachedMarketsItem[]) {
    const expiryTime = new Date(Date.now() - this.CACHED_MARKET_EXPIRY_TIME_HRS * 60 * 60 * 1000);
    const markets = cachedMarkets.filter(
      ({ lastAccessedDate }) => new Date(lastAccessedDate) > expiryTime
    );
    localStorage.setItem(this.CACHED_MARKET_IDS, JSON.stringify(markets));
    return markets;
  }

  /**
   * Get cached market items from storage.
   */
  getCachedMarkets() {
    const value = localStorage.getItem(this.CACHED_MARKET_IDS) ?? JSON.stringify([]);
    const parsed = JSON.parse(value) as (number | CachedMarketsItem)[];
    const migratedCachedMarkets = this.migrateCachedMarketIds(parsed);
    const cachedMarkets = this.removeExpiredCachedMarkets(migratedCachedMarkets);
    return cachedMarkets;
  }

  /**
   * Retreives cached market ids.
   */
  getCachedMarketIds() {
    return this.getCachedMarkets().map(({ id }) => id);
  }

  /**
   * Syncing market data into the indexedDb and caching it.
   */
  async cacheMarket(marketId: number) {
    // Check if the navigator is online, if the market has not been already cached
    // and if the API service is available. If any of these conditions are not met,skip market caching.
    const isApiCallAvailable = await this.checkApiCallAvailability();

    // Check if the API is unavailable and the market data is not cached; if both conditions are met,
    // throw an application error because of not having cached data.
    if (!isApiCallAvailable && !this.isMarketAlreadyCached(marketId)) {
      throw new AppException(
        "This country's data cannot be downloaded because you are offline or the backend API is not functioning."
      );
    }

    this.setLastVisitedMarketId(marketId);

    // This control is required for when page refreshed, cacheMarket should not call API if data has been already fetched.
    if (this.isMarketAlreadyCached(marketId)) {
      this.cacheMarketId(marketId);
      return;
    }

    await this.resetPreviouslyUnsuccessfulEntities();

    const fetchEntitiesResponse = await this.fetchEntities({
      marketIds: [marketId],
      fetchMarketEntitiesOnly: true,
    });

    if (fetchEntitiesResponse.unSuccesfulFetchedEntities.length) {
      throw Error(
        `Could not fetch specific entities during cache market: ${fetchEntitiesResponse.unSuccesfulFetchedEntities
          .map(({ entityType }) => entityType)
          .join(', ')}`
      );
    }

    this.cacheMarketId(marketId);
    this.broadcastReceivedEntities(fetchEntitiesResponse.fetchedEntities);
  }

  /**
   * Fetches markets from the api.
   */
  private async fetchMarkets(): Promise<FetchEntityResponse> {
    const entitiesMeta = await this.localDbManager.getAllMetadata();
    const marketMetaData = syncingConfig.markets;
    const marketEntityMeta = entitiesMeta.find(item => item.entityType === 'markets');
    const fetchEntityResponse = await this.fetchEntity(
      [],
      marketEntityMeta,
      'markets',
      marketMetaData
    );

    return this.putAndCacheEntities(fetchEntityResponse);
  }

  private async putAndCacheEntities(
    fetchedEntityResponse: FetchEntityResponse
  ): Promise<FetchEntityResponse> {
    let putAndCacheEntitiesResponse = { ...fetchedEntityResponse };

    // Try to put data into indexedDB with 3 attempts
    const MAX_FAILURE_ATTEMPT = 3;
    let attemptCount = 0;

    const transactions = await this.localDbManager.getAllTransactions();
    do {
      try {
        // Firstly fetched data from server should be tried to put in indexed db....
        await this.localDbManager.putEntities(
          fetchedEntityResponse.entities,
          fetchedEntityResponse.entityType
        );

        // Put waiting transactions in queue to the cache and response of the entities in order update the grid correct values.
        const entitiesInTransactions = transactions
          .filter(transaction => transaction.entityType === fetchedEntityResponse.entityType)
          .reduce((entities: Entity[], transaction) => {
            if (transaction.type === TransactionType.BulkUpdate) {
              const fetchedEntities = this.queueManager.getEntities(transaction);

              if (fetchedEntities) {
                entities.push(...fetchedEntities);
              }
            } else {
              const fetchedEntity = this.queueManager.getEntity(transaction);

              if (fetchedEntity) {
                entities.push(fetchedEntity);
              }
            }

            return entities;
          }, []);

        const collectorEntity = entityConfiguration.entities[fetchedEntityResponse.entityType];

        if (collectorEntity && entitiesInTransactions.length > 0) {
          for (const entity of entitiesInTransactions) {
            if (entity['isDeleted']) {
              // delete before caching
              fetchedEntityResponse.entities = fetchedEntityResponse.entities.filter(
                item => item[collectorEntity.keyPath] !== entity[collectorEntity.keyPath]
              );
            } else {
              fetchedEntityResponse.entities = findAndReplace<Entity>(
                item => item[collectorEntity.keyPath] === entity[collectorEntity.keyPath],
                fetchedEntityResponse.entities,
                entity
              );
            }
          }
        }

        // Put joining fetched data to transaction data into the cache.
        this.cachingManager.cacheEntities({
          [fetchedEntityResponse.entityType]: fetchedEntityResponse.entities,
        });

        // If there is not any error while putting data into indexedDB entity table, metadata of this entity should be updated.
        // If there is any exception occurs while putting entities to indexedDb, metadata will not updated so in next iteration,
        // researcher will try to get the same data and put it into indexedDb.
        await this.localDbManager.putMetadata({
          ...fetchedEntityResponse.entityMetaData,
        });

        // if it is succesfull, stop trying to put data again.
        break;
      } catch (err) {
        // If any error occurs during putting entities to indexedDB, do update metadata status Failed only
        // in order to try to fetch the same data again.
        // This line is removing the risk of having infinitive INPROGRESS state for _metadata table.
        await this.localDbManager.updateMetadata(fetchedEntityResponse.entityType, {
          status: EntitySyncState.Failed,
        });

        putAndCacheEntitiesResponse = {
          ...fetchedEntityResponse,
          entityMetaData: {
            ...fetchedEntityResponse.entityMetaData,
            status: EntitySyncState.Failed,
          },
        };
      } finally {
        attemptCount++;
      }
    } while (attemptCount < MAX_FAILURE_ATTEMPT);

    return putAndCacheEntitiesResponse;
  }

  private async fetchEntityVersions(): Promise<EntityVersion[]> {
    const entityVersionsResponse = await this.doFetchRequestWithRetry('entityVersions');

    if (entityVersionsResponse.status !== HttpStatusCode.SuccessOK) {
      throw Error('Entity versions can not fetch!');
    }

    return entityVersionsResponse.data as EntityVersion[];
  }

  private async checkEntitiesVersion() {
    const entityVersions = await this.fetchEntityVersions();
    const entitiesMeta = await this.localDbManager.getAllMetadata();

    for (const [entityType] of Object.entries(syncingConfig.entities)) {
      const entityVersion = entityVersions.find(item => item.entityType === entityType);
      const entityMeta = entitiesMeta.find(item => item.entityType === entityType);
      if (entityVersion?.version !== entityMeta?.version) {
        this.cachingManager.clearEntityData(entityType);

        await Promise.all([
          this.localDbManager.putMetadata({
            entityType,
            firstSyncedDate: undefined,
            lastSyncedDate: undefined,
            lastUpdatedDate: undefined,
            status: EntitySyncState.VersionChanged,
            version: entityVersion?.version,
          }),
          this.localDbManager.clearEntities(entityType),
        ]);
      }
    }
  }

  /**
   * Fetches all entities.
   */
  private async fetchEntities({
    marketIds,
    fetchMarketEntitiesOnly,
    isBootstrap,
  }: FetchEntitiesRequest): Promise<FetchEntitiesResponse> {
    const entitiesMeta = await this.localDbManager.getAllMetadata();
    const syncingPromise: Promise<FetchEntityResponse>[] = [];

    for (const [entityType, entitySchema] of Object.entries(syncingConfig.entities)) {
      const entityMeta = entitiesMeta.find(item => item.entityType == entityType);

      if (fetchMarketEntitiesOnly && entitySchema.isMarketQuery) {
        syncingPromise.push(
          this.fetchEntity(marketIds, entityMeta, entityType, entitySchema, true)
        );
      } else if (!fetchMarketEntitiesOnly) {
        syncingPromise.push(this.fetchEntity(marketIds, entityMeta, entityType, entitySchema));
      }
    }

    let fetchEntitiesResponse = await Promise.all(syncingPromise);
    const unSuccesfulFetchedEntities = this.getUnsuccessfulEntities(fetchEntitiesResponse);

    for (let fetchEntityResponse of fetchEntitiesResponse) {
      // check if all entities fetch succesfully or if it is bootstrap.
      if (unSuccesfulFetchedEntities.length === 0 || isBootstrap) {
        fetchEntityResponse = await this.putAndCacheEntities(fetchEntityResponse);

        fetchEntitiesResponse = findAndReplace<FetchEntityResponse>(
          item => item.entityType === fetchEntityResponse.entityType,
          fetchEntitiesResponse,
          fetchEntityResponse
        );
      } else if (
        unSuccesfulFetchedEntities.find(item => item.entityType === fetchEntityResponse.entityType)
      ) {
        // if entity is fetched unsuccesfully, put result of metadata to the localdb.
        await this.localDbManager.putMetadata({
          ...fetchEntityResponse.entityMetaData,
        });
      } else {
        // this line is required to return in progress status to success status without changing any date fields in case of any other fetching entity gets exception.
        await this.localDbManager.updateMetadata(fetchEntityResponse.entityType, {
          status: EntitySyncState.Success,
        });
      }
    }

    return {
      fetchedEntities: Object.fromEntries(
        fetchEntitiesResponse.map(item => [item.entityType, item.entities])
      ),
      unSuccesfulFetchedEntities,
    };
  }

  /**
   * Function to manage fetching entity progress.
   */
  private async fetchEntity(
    marketIds: number[],
    entityMeta: EntityMetadata | undefined,
    entityType: string,
    entitySchema: EntitySchema,
    ignoreLastUpdatedDate?: boolean
  ): Promise<FetchEntityResponse> {
    const entities: Entity[] = [];

    if (entityMeta?.status !== EntitySyncState.InProgress) {
      let status = EntitySyncState.InProgress;
      let firstSyncedDate = entityMeta?.firstSyncedDate;
      let lastSyncedDate = entityMeta?.lastSyncedDate;
      let lastUpdatedDate = entityMeta?.lastUpdatedDate;
      const version = entityMeta?.version;
      const queryStringParameters = entitySchema.isMarketQuery
        ? {
            marketIds: marketIds.join(',').toString(),
          }
        : undefined;

      await this.localDbManager.putMetadata({
        status,
        entityType,
        firstSyncedDate,
        lastSyncedDate,
        lastUpdatedDate,
        version,
      });

      const path = entitySchema.path ?? entityType;

      const fetchDataResult = await this.fetchData(
        path,
        ignoreLastUpdatedDate ? undefined : lastUpdatedDate,
        queryStringParameters
      );

      if (fetchDataResult.httpStatusCode === HttpStatusCode.SuccessOK && fetchDataResult.data) {
        status = EntitySyncState.Success;
        // we should use ISOString instead of UTCString, because UTCString is not providing milliseconds.
        firstSyncedDate ??= new Date().toISOString();
        lastSyncedDate = ignoreLastUpdatedDate
          ? entityMeta?.lastSyncedDate
          : new Date().toISOString();
        lastUpdatedDate = ignoreLastUpdatedDate
          ? entityMeta?.lastUpdatedDate
          : fetchDataResult.lastUpdatedDate;
        entities.push(...fetchDataResult.data);
      } else if (fetchDataResult.httpStatusCode === HttpStatusCode.NotModified) {
        status = EntitySyncState.Success;
        firstSyncedDate ??= new Date().toISOString();
        lastSyncedDate = ignoreLastUpdatedDate
          ? entityMeta?.lastSyncedDate
          : new Date().toISOString();
      } else if (fetchDataResult.httpStatusCode === HttpStatusCode.ServiceNotAvailable) {
        status = EntitySyncState.TimedOut;
      } else {
        status = EntitySyncState.Failed;
      }

      this.broadcastSyncPercentageChange();

      return {
        entities,
        entityType,
        entityMetaData: {
          status,
          entityType,
          firstSyncedDate,
          lastSyncedDate,
          lastUpdatedDate,
          version,
        },
      };
    }

    return {
      entities,
      entityType,
      entityMetaData: entityMeta,
    };
  }

  /**
   * Function to fetch any entity data from the api.
   */
  private async fetchData(
    path: string,
    modifiedSince?: string,
    queryStringParameters?: Record<string, string>
  ): Promise<FetchDataResponse> {
    try {
      let data: Entity[] = [];
      let response: ApiResponse;
      let lastItemKey: string | undefined;
      let lastUpdatedDate: string | undefined;
      let parameters: Record<string, string> = {
        ...queryStringParameters,
      };

      do {
        if (lastItemKey) {
          parameters = {
            ...queryStringParameters,
            lastItemKey,
          };
        }
        response = await this.doFetchRequestWithRetry(path, modifiedSince, parameters);

        const lastModifiedHeader = response.headers['x-last-modified'];

        // During pagination if new page lastModifiedHeader values is bigger
        // than local, it should replace this new value with local.
        if (lastModifiedHeader) {
          if (!lastUpdatedDate) {
            lastUpdatedDate = lastModifiedHeader;
          } else {
            if (Date.parse(lastUpdatedDate) < Date.parse(lastModifiedHeader)) {
              lastUpdatedDate = lastModifiedHeader;
            }
          }
        }

        //if response is array that means it is not paginated response.
        if (Array.isArray(response.data)) {
          data.push(...(response.data as Entity[]));
        } else {
          const responseData = response.data as PaginatedData;
          data.push(...responseData.data);
          lastItemKey = responseData.lastItemKey;
        }
      } while (lastItemKey !== undefined);

      // during the pagination if any pagination gets any error, it should not put partial data into indexeddb, it should return empty for this paginated fetching.
      if (
        response.status !== HttpStatusCode.SuccessOK &&
        response.status !== HttpStatusCode.NotModified
      ) {
        data = [];
        lastUpdatedDate = undefined;
      }

      return {
        httpStatusCode: response.status,
        lastUpdatedDate,
        data,
      };
    } catch (err) {
      const apiError = err as ApiError;

      if (apiError.isAxiosError) {
        return {
          httpStatusCode: apiError.response?.status ?? HttpStatusCode.NetworkError,
          data: [],
        };
      } else {
        return {
          httpStatusCode: HttpStatusCode.ServiceNotAvailable,
          data: [],
        };
      }
    }
  }

  /**
   * Function to help retry fetching data on failure
   */
  private async doFetchRequestWithRetry(
    path: string,
    modifiedSince?: string,
    queryStringParameters?: Record<string, string>
  ) {
    let currentRetryCount = 0;
    let response: ApiResponse | undefined;
    let isRetryRequiredRequest = false;
    let requestError: unknown;

    do {
      try {
        response = await doFetchRequest({
          path,
          modifiedSince,
          queryStringParameters,
        });
      } catch (err) {
        requestError = err;
        const apiError = err as ApiError;

        // in some case (like Network Error) response can return undefined, so in this case it should be tried again.
        if (!apiError.response) {
          isRetryRequiredRequest = true;
        } else {
          // if it is axios error it should try to fetch data when server returns any error.
          isRetryRequiredRequest = apiError.isAxiosError ?? false;
        }
      }

      currentRetryCount++;
    } while (
      currentRetryCount < this.FETCH_REQUEST_RETRY_COUNT &&
      isRetryRequiredRequest &&
      !response
    );

    if (requestError || !response) {
      throw requestError;
    }

    return response;
  }

  /**
   * Retrives assigned market ids.
   */
  private async getAssignedMarketIds() {
    const markets = await this.localDbManager.getAllEntities('markets');

    return markets
      .filter(item => item['isWrite'] || item['isMaintain'] || item['isAdmin'])
      .map(item => (item['marketId'] ? (item['marketId'] as number) : undefined))
      .filter((item): item is number => !!item);
  }

  /**
   * Checks if market is already cached.
   */
  private isMarketAlreadyCached = (marketId: number) => {
    const cachedMarketIds = this.getCachedMarketIds();
    return cachedMarketIds.some(id => id === marketId);
  };

  /**
   * Filtering unsuccessful entities.
   */
  private getUnsuccessfulEntities(fetchEntitiesResponse: FetchEntityResponse[]) {
    const metaList = fetchEntitiesResponse.map(item => item.entityMetaData);

    return metaList.filter(meta => meta.status !== EntitySyncState.Success);
  }

  /**
   * Caching market id to the local storage.
   */
  private cacheMarketId(marketId: number) {
    const cachedMarkets = this.getCachedMarkets();

    const cachedMarketItemIndex = cachedMarkets.findIndex(({ id }) => id === marketId);

    if (cachedMarketItemIndex > -1) {
      cachedMarkets[cachedMarketItemIndex]!.lastAccessedDate = new Date().toISOString();
    } else {
      cachedMarkets.push({ id: marketId, lastAccessedDate: new Date().toISOString() });
    }

    localStorage.setItem(this.CACHED_MARKET_IDS, JSON.stringify(cachedMarkets));
  }

  /**
   * Caching market ids to the local storage.
   */
  private cacheMarketIds = (marketIds: number[]) =>
    marketIds.forEach(marketId => this.cacheMarketId(marketId));

  private async getBootstrapType() {
    const entitiesMeta = await this.localDbManager.getAllMetadata();

    if (entitiesMeta.length === 0) {
      return BootstrapType.Full;
    }

    const entityMeta = entitiesMeta.find(item => item.entityType == 'markets');

    if (!entityMeta || entityMeta.status !== EntitySyncState.Success) {
      return BootstrapType.Partial;
    }

    for (const [entityType] of Object.entries(syncingConfig.entities)) {
      const entityMeta = entitiesMeta.find(item => item.entityType == entityType);
      if (!entityMeta || entityMeta.status !== EntitySyncState.Success) {
        return BootstrapType.Partial;
      }
    }

    return BootstrapType.Local;
  }

  /**
   * Reset previously unsuccessfull entities.
   */
  private async resetPreviouslyUnsuccessfulEntities() {
    const entitiesMeta = await this.localDbManager.getAllMetadata();

    for (const meta of entitiesMeta) {
      if (meta.status !== EntitySyncState.Success) {
        await this.localDbManager.putMetadata({
          ...meta,
          status: EntitySyncState.Queued,
        });
      }
    }
  }

  /**
   * Notify subscribers when sync state is changed.
   */
  private setSyncMagerState(status: SyncManagerState) {
    this.status = status;
    this.onSyncManagerStateChange.notify(status);
  }

  private broadcastSyncManagerStateChange() {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.SyncManagerStateChange,
      payload: this.status,
    });
  }

  private broadcastReceivedEntities(data: Record<string, Entity[]>) {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.EntitiesReceived,
      payload: data,
    });
  }

  private broadcastSyncPercentageChange() {
    this.numberOfSucceedSyncEntities += 1;
    // Lenght of entites + market entity + 2 more broadcasts calls during delta sync
    const numberOfTotalSyncEntities = Object.keys(syncingConfig.entities).length + 3;
    this.syncingPercentage = Math.round(
      (this.numberOfSucceedSyncEntities / numberOfTotalSyncEntities) * 100
    );
    this.onSyncPercentageChange.notify(this.syncingPercentage);
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.SyncPercentageChange,
      payload: this.syncingPercentage,
    });
  }

  private setSyncingPercentage(percentage: number) {
    this.syncingPercentage = percentage;
    this.onSyncPercentageChange.notify(this.syncingPercentage);
  }

  public getLastVisitedMarketId() {
    const marketIdString = localStorage.getItem(this.LAST_VISITED_MARKET);

    return marketIdString ? parseInt(marketIdString) : undefined;
  }

  public setLastVisitedMarketId(marketId: number) {
    localStorage.setItem(this.LAST_VISITED_MARKET, marketId.toString());
  }
}
