import type { DBSchema } from 'idb';

import type { Entity } from '../types/Entity';

export enum BootstrapType {
  Full = 'Full',
  Partial = 'Partial',
  Local = 'Local',
}

export type EntityReview = Record<string, unknown>;

export type EntitiesLookup = Record<string, Map<string | number, Entity | undefined>>;

export interface EntityConfiguration {
  entities: Record<string, EntitySchema>;
  schemaVersion: number;
}

export interface EntityMetadata {
  entityType: string;
  firstSyncedDate: string | undefined;
  lastSyncedDate: string | undefined;
  lastUpdatedDate: string | undefined;
  version: number | undefined;
  status: EntitySyncState;
}

export interface EntitySchema {
  keyPath: string;
  /** @TODO - rename this, as it's not just a query */
  isMarketQuery?: boolean;
  /** @deprecated - we should remove meta prefix */
  path?: string;
}

export interface LocalDBSchema extends DBSchema {
  _meta: {
    key: string;
    value: EntityMetadata;
  };
  _transactions: {
    key: string;
    value: Transaction;
  };
  _transactionLog: {
    key: string;
    value: TransactionLog;
    indexes: {
      '_index[auditId]': string;
      '_index[transactionId]': string;
      '_index[verifiedDate]': string;
    };
  };
  entity: {
    key: string;
    value: Entity;
  };
}

export enum OnlineState {
  Online = 'Online',
  Offline = 'Offline',
}

// An entity sync state.
export enum EntitySyncState {
  Queued = 'Queued',
  InProgress = 'In progress',
  Success = 'Success',
  Failed = 'Failed',
  TimedOut = 'Timed out',
  VersionChanged = 'Version changed',
}

// Syncing manager state.
export enum SyncManagerState {
  InProgress = 'In progress',
  Failed = 'Failed',
  TimedOut = 'Timed out',
  Success = 'Success',
}

// An entity transaction state
export enum TransactionSyncState {
  Queued = 'Queued',
  Success = 'Success',
  Failed = 'Failed',
  TimedOut = 'Timed out',
}

// Queue manager state.
export enum QueueManagerState {
  InProgress = 'In progress',
  Success = 'Success',
  Failed = 'Failed',
  TimedOut = 'Timed out',
}

// Sync context (Sync State) overall state
export enum SyncContextState {
  Available = 'Available',
  Caching = 'Caching',
  Saving = 'Saving',
  Offline = 'Offline',
  ServerOffline = 'Server offline',
  Failed = 'Failed',
  BootstrapError = 'Bootstrap error',
}

interface BaseTransaction {
  entityGUID?: string;
  entityType: string;
  id?: string;
  transactionId: string;
  status: TransactionSyncState;
  type: TransactionType;
  createdAt: Date;
  actionType?: string | undefined;
}

export interface CreateTransaction extends BaseTransaction {
  payload: Entity;
  type: TransactionType.Create;
}

export interface DeleteTransaction extends BaseTransaction {
  entityGUID: string;
  type: TransactionType.Delete;
}

export interface ReviewTransaction extends BaseTransaction {
  entityGUID: string;
  payload: EntityReview;
  type: TransactionType.Review;
}

export interface UpdateTransaction extends BaseTransaction {
  entityGUID: string;
  payload: Partial<Entity>;
  type: TransactionType.Update;
}

export interface BulkUpdateTransaction extends BaseTransaction {
  payload: Partial<Entity>[];
  type: TransactionType.BulkUpdate;
}

export type Transaction =
  | CreateTransaction
  | DeleteTransaction
  | ReviewTransaction
  | UpdateTransaction
  | BulkUpdateTransaction;

export type BulkTransaction = BulkUpdateTransaction;

export type NonBulkTransaction = Exclude<Transaction, BulkTransaction>;

export enum TransactionType {
  Create = 'create',
  Update = 'update',
  BulkUpdate = 'bulkUpdate',
  Review = 'review',
  Delete = 'delete',
}

export type TransactionLog = Omit<BaseTransaction, 'status'> & {
  status?: string; // needs to be there to be able to be deleted
  payload?: Entity | EntityReview | Partial<Entity> | Partial<Entity>[];
  auditId?: number;
  verifiedDate?: Date | null;
};

type Error = Record<string, unknown>;

export enum HttpStatusCode {
  SuccessOK = 200,
  SuccessCreated = 201,
  NoContent = 204,
  AlreadyReported = 208,
  NotModified = 304,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  UnprocessableEntity = 422,
  ServiceNotAvailable = 503,
  GatewayTimeout = 504,
  NetworkError = 0,
}

export interface PaginatedData {
  data: Entity[];
  lastItemKey?: string | undefined;
}

export interface ApiResponse {
  status: HttpStatusCode;
  data: PaginatedData | Entity | Entity[] | Error | unknown;
  headers: Record<string, string>;
}

export interface ApiError {
  isAxiosError?: boolean;
  response: ApiResponse | undefined;
}

export type RequestPayload = Partial<Entity>;

interface BaseRequestParams {
  path: string;
  timeoutInMilliseconds?: number | undefined;
}

interface FetchRequestParams extends BaseRequestParams {
  modifiedSince?: string | undefined;
  queryStringParameters?: Record<string, string> | undefined;
}

interface ModificationRequestParams extends BaseRequestParams {
  transactionId?: string | undefined;
  requestPayload?: RequestPayload | RequestPayload[] | undefined;
}

export type DoPostRequestParams = ModificationRequestParams;
export type DoPatchRequestParams = ModificationRequestParams;
export type DoPutRequestParams = ModificationRequestParams;
export type DoDeleteRequestParams = Omit<ModificationRequestParams, 'requestPayload'>;
export type DoFetchRequestParams = FetchRequestParams;
export type RequestInitParams = Omit<
  ModificationRequestParams & FetchRequestParams,
  'path' | 'transactionId'
> & { transactionId?: string | undefined };

export interface FetchDataResponse {
  httpStatusCode: HttpStatusCode;
  lastUpdatedDate?: string | undefined;
  data?: Entity[] | undefined;
}

export interface FetchEntityResponse {
  entityType: string;
  entities: Entity[];
  entityMetaData: EntityMetadata;
}

export interface FetchEntitiesRequest {
  marketIds: number[];
  fetchMarketEntitiesOnly?: boolean;
  isBootstrap?: boolean;
}

export interface FetchEntitiesResponse {
  fetchedEntities: Record<string, Entity[]>;
  unSuccesfulFetchedEntities: EntityMetadata[];
}

export enum BroadcastMessageType {
  SyncManagerStateChange = 'SyncManagerStateChange',
  SyncPercentageChange = 'SyncPercentageChange',
  QueueManagerStateChange = 'QueueManagerStateChange',
  EntitiesReceived = 'EntitiesReceived',
  TransactionCreated = 'TransactionCreated',
  TransactionRemoved = 'TransactionRemoved',
  Disconnect = 'Disconnect',
}

interface SyncManagerStateMessage {
  payload: SyncManagerState;
  type: BroadcastMessageType.SyncManagerStateChange;
}

interface SyncPercentageChangeMessage {
  payload: number;
  type: BroadcastMessageType.SyncPercentageChange;
}

interface QueueManagerStateChangeMessage {
  payload: QueueManagerState;
  type: BroadcastMessageType.QueueManagerStateChange;
}

interface EntitiesReceivedMessage {
  payload: Record<string, Entity[]>;
  type: BroadcastMessageType.EntitiesReceived;
}

interface TransactionCreatedMessage {
  payload: Transaction;
  type: BroadcastMessageType.TransactionCreated;
}

interface TransactionRemovedMessage {
  payload: Transaction;
  type: BroadcastMessageType.TransactionRemoved;
}

interface DisconnectMessage {
  type: BroadcastMessageType.Disconnect;
}

export type BroadcastMessage =
  | SyncManagerStateMessage
  | SyncPercentageChangeMessage
  | EntitiesReceivedMessage
  | QueueManagerStateChangeMessage
  | TransactionCreatedMessage
  | TransactionRemovedMessage
  | DisconnectMessage;

export interface ApiAuditData {
  auditId: number;
  method: string;
  requestBody: string;
  transactionId: string;
  username: string;
}

export interface CachedMarketsItem {
  id: number;
  lastAccessedDate: string;
}
